'use client';

import cmsItemsArrayIsEqual from '@haaretz/s-common-utils/cmsItemsArrayIsEqual';
import React from 'react';

import type { AuthorFragment } from '@haaretz/s-fragments/Author';

export type AuthorsAtomType = Pick<AuthorFragment, 'contentId' | 'name'>[] | null;

const AuthorsContext = React.createContext<AuthorsAtomType | undefined>(undefined);
const AuthorsSetter = React.createContext<((authors: AuthorsAtomType) => void) | undefined>(
  undefined
);

export function AuthorsProvider({
  children,
  value: defaultAuthors,
}: React.PropsWithChildren<{ value: AuthorFragment[] | null }>) {
  const [authors, setAuthors] = React.useState<AuthorsAtomType | null>(defaultAuthors);

  const authorsSetter = React.useCallback(
    (value: AuthorsAtomType) => {
      if ((Array.isArray(value) || value === null) && !cmsItemsArrayIsEqual(value, authors)) {
        setAuthors(value);
      } else if (
        value &&
        !Array.isArray(value) &&
        typeof value === 'object' &&
        Object.keys(value).length &&
        !cmsItemsArrayIsEqual([value], authors)
      ) {
        setAuthors([value]);
      }
    },
    [authors]
  );

  return (
    <AuthorsContext value={authors}>
      <AuthorsSetter value={authorsSetter}>{children}</AuthorsSetter>
    </AuthorsContext>
  );
}

export default function useAuthors() {
  const context = React.use(AuthorsContext);

  if (context === undefined) {
    throw new Error('useAuthors must be used within a AuthorsProvider');
  }

  return context;
}

export function useSetAuthors() {
  const context = React.use(AuthorsSetter);

  if (context === undefined) {
    throw new Error('useSetAuthors must be used within a AuthorsProvider');
  }

  return context;
}

export function useAuthorsAtom() {
  return [useAuthors(), useSetAuthors()] as const;
}
