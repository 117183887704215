import { usePurchasePagePersonalQuery } from '@haaretz/s-pp-queries/PurchasePage';
import { usePPDataStore } from '@haaretz/s-use-pp-data-store';
import usePpDataVariables from '@haaretz/s-use-pp-data-variables';
import React from 'react';

import type { SelectedOfferProps } from '@haaretz/s-data-structure-types';
import type { PurchasePagePersonalQueryVariables } from '@haaretz/s-pp-queries/PurchasePage';

export default function useSelectedOffer() {
  const commonVariables = usePpDataVariables();
  const store = usePPDataStore();

  const variables: PurchasePagePersonalQueryVariables = React.useMemo(() => {
    return {
      ...(store.contentId ? { selectedOfferId: store.contentId } : {}),
      ...commonVariables,
    };
  }, [commonVariables, store.contentId]);

  const { data } = usePurchasePagePersonalQuery(variables, {
    enabled: !commonVariables.selectedOfferId && !!variables.selectedOfferId,
  });

  const selectedOfferData = data?.PurchasePage?.pageData?.selectedOffer;

  if (!selectedOfferData) return null;

  const selectedOffer: SelectedOfferProps = {
    contentId: selectedOfferData?.contentId,
    periodCalc: selectedOfferData?.periodCalc,
    prices: selectedOfferData?.prices,
    productNumber: selectedOfferData?.productNumber,
    paymentType: selectedOfferData?.paymentType,
    promotionNumber: selectedOfferData?.promotionNumber,
    saleCode: selectedOfferData?.saleCode,
    thankYouMailTemplate: selectedOfferData?.thankYouMailTemplate,
    offerType: selectedOfferData?.offerType,
    currency: selectedOfferData?.currency,
  };

  return selectedOffer;
}
