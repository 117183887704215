'use client';

import isStorybook from '@haaretz/s-common-utils/isStorybook';
import * as React from 'react';

import useCollectBiData from './helpers/useCollectBiData';

import type { BiData } from '@haaretz/s-data-structure-types';

const BiDataContext = React.createContext<BiData | undefined>(undefined);

const noop = () => ({});

let useCollectBiDataOverride = useCollectBiData;

export function BiDataContextProvider({ children }: React.PropsWithChildren) {
  if (isStorybook()) {
    useCollectBiDataOverride = noop;
  }

  const biData = useCollectBiDataOverride();

  return <BiDataContext value={biData}>{children}</BiDataContext>;
}

export default function useBiDataContext() {
  const context = React.use(BiDataContext);

  if (context === undefined) {
    throw new Error('useBiDataContext must be used within a BiDataContext.');
  }

  return context;
}
