import config from '@haaretz/l-config';
import usePlatform from '@haaretz/s-atoms/platform';
import usePreviewId from '@haaretz/s-atoms/previewId';
import discardFields from '@haaretz/s-common-utils/discardFields';
import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import isTeaserBiData from '@haaretz/s-type-predicates/isTeaserBiData';
import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';
import useOnce from '@haaretz/s-use-once';
import React from 'react';

import getBiData from './helpers/getBiData';
import getTeaserBiData from './helpers/getTeaserBiData';
import useBiDataContext from './useBiDataContext';

import type { BiFunction, BiType } from './helpers/types';
import type { BiDataOverrides } from '@haaretz/s-data-structure-types';

const biServer = config.get('bIServer');
export default function useBi(biType: BiType = 'action'): BiFunction {
  const [lazyBiDataArr, setLazyBiDataArr] = React.useState<BiDataOverrides[]>([]);

  const contextBiData = useBiDataContext();
  const isLoaded = useLoadEvent();
  const platform = usePlatform();
  const isPreview = !!usePreviewId();
  const lastSendedUrl = React.useRef<string | null>(null);

  const collectData: BiFunction = React.useCallback(
    originUserBiData => {
      if (isPreview) {
        return;
      }

      const userBiData = isTeaserBiData(originUserBiData)
        ? getTeaserBiData(originUserBiData, biType)
        : getBiData(originUserBiData);

      if (!isLoaded) {
        if (biType !== 'pageview') {
          setLazyBiDataArr(prevLazyBiDataArr => [...prevLazyBiDataArr, userBiData]);
        }

        return;
      }

      let biUrl: string;

      switch (biType) {
        case 'impression':
          biUrl = `${biServer}/impressionPlain`;
          break;
        case 'pageview':
          if (lastSendedUrl.current === contextBiData.url) {
            // NOTE: Early return if the URL was already sent to BI
            return;
          } else {
            lastSendedUrl.current = contextBiData.url || null;
          }

          biUrl = `${biServer}/requestPlain`;

          if (contextBiData.article_id) {
            // NOTE: Add articleId to the URL for pageview for better BI debugging
            biUrl = `${biUrl}?articleId=${contextBiData.article_id}`;
          }
          break;
        default:
          biUrl = `${biServer}/actionPlain`;
      }
      const searchParams = new URLSearchParams(document.location.search);

      const additional_info = JSON.stringify({
        ...(userBiData?.additional_info ?? {}),
        ...(searchParams.has('gift')
          ? {
              gift: searchParams.get('gift'),
            }
          : {}),
        // NOTE: Put here common additional info that should be sent to BI
      });

      let finalBiData = { ...contextBiData, ...userBiData, additional_info };

      if (biType !== 'action' && finalBiData) {
        finalBiData = discardFields(finalBiData, ['action_id']);
      }

      window.navigator?.sendBeacon(biUrl, JSON.stringify(discardNullableValues(finalBiData)));
    },
    [biType, contextBiData, isLoaded, isPreview]
  );

  useOnce(
    () => {
      lazyBiDataArr.forEach(data => {
        collectData(data);
      });
    },
    platform === 'app' && isLoaded && !isPreview
  );

  return collectData;
}
