'use client';

import { PP_STORE_DATA_KEY } from '@haaretz/s-consts';
import removeQueryParam from '@haaretz/s-navigation-utils/removeQueryParam';
import { useRouter } from 'next/router';
import React, { PropsWithChildren } from 'react';

import type { CartDataFragment } from '@haaretz/s-fragments/CartData';
import type { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';

export interface PPDataStoreOfferContextProps {
  contentId?: PurchaseOfferFragment['contentId'];
}

export interface PPDataStoreDebtContextProps {
  approveDebtClaim?: CartDataFragment['approveDebtClaim'];
}

export interface PPDataStoreGlobalContextProps {
  referrer?: string;
  approveDebtClaim?: CartDataFragment['approveDebtClaim'];
}

export interface PPDataStoreContextProps
  extends PPDataStoreOfferContextProps,
    PPDataStoreGlobalContextProps,
    PPDataStoreDebtContextProps {}

interface PPDataStoreActionsContextProps<T extends PPDataStoreContextProps> {
  updateStore: (newState: T) => void;
  clearStore: (field?: keyof T) => void;
}

function actionsContextFactory() {
  const Context = React.createContext<
    PPDataStoreActionsContextProps<PPDataStoreContextProps> | undefined
  >(undefined);
  return function <T extends PPDataStoreContextProps>() {
    return Context as React.Context<PPDataStoreActionsContextProps<T> | undefined>;
  };
}

function dataContextFactory() {
  const Context = React.createContext<PPDataStoreContextProps | undefined>(undefined);
  return function <T extends PPDataStoreContextProps>() {
    return Context as React.Context<T | undefined>;
  };
}

const getPPDataStoreActionsContext = actionsContextFactory();
const getPPDataStoreContext = dataContextFactory();
const PPCouponStoreContext = React.createContext<string | null | undefined>(undefined);

const PPCouponUpdateStoreContext = React.createContext<
  React.Dispatch<React.SetStateAction<string | null>> | undefined
>(undefined);

export default function PPDataStoreContextProvider<T extends PPDataStoreContextProps>({
  children,
  initialState,
}: PropsWithChildren<{ initialState?: PPDataStoreContextProps | undefined }>) {
  const [store, setStore] = React.useState<T>((initialState || {}) as T);
  const [coupon, setCoupon] = React.useState<string | null>(null);

  const storeActions = React.useMemo(() => {
    const updateStore = (newState: T) => {
      setStore(prevState => ({ ...prevState, ...newState }));
    };

    const clearStore = (field?: keyof T) =>
      setStore((prevState): T => {
        if (field) {
          const { [field]: deleted, ...clearedState } = prevState;
          return clearedState as T;
        }
        return { referrer: prevState.referrer } as T;
      });

    return {
      updateStore,
      clearStore,
    };
  }, []);

  React.useEffect(() => {
    const referrer = document.referrer;

    if (referrer && !store.referrer) {
      storeActions.updateStore({ referrer } as T);
    }
  }, [storeActions, store.referrer]);

  const PPDataStoreContext = getPPDataStoreContext<T>();
  const PPDataStoreActionsContext = getPPDataStoreActionsContext<T>();

  return (
    <PPCouponStoreContext value={coupon}>
      <PPCouponUpdateStoreContext value={setCoupon}>
        <PPDataStoreContext value={store}>
          <PPDataStoreActionsContext value={storeActions}>{children}</PPDataStoreActionsContext>
        </PPDataStoreContext>
      </PPCouponUpdateStoreContext>
    </PPCouponStoreContext>
  );
}

////////////
//  Hook  //
////////////

export function usePPDataStore<T extends PPDataStoreContextProps>() {
  const context = React.use(getPPDataStoreContext<T>());

  if (!context) {
    throw new Error('usePPDataStore must be used within a PPDataStoreContextProvider');
  }

  return context;
}

export function usePPDataStoreActions<T extends PPDataStoreContextProps>() {
  const context = React.use(getPPDataStoreActionsContext<T>());

  if (!context) {
    throw new Error(
      'usePPDataStoreActions must be used within a PPDataStoreActionsContextProvider'
    );
  }

  return context;
}

export function usePPCouponStore() {
  const context = React.use(PPCouponStoreContext);

  if (context === undefined) {
    throw new Error('usePPCouponStore must be used within a PPCouponStoreContext');
  }

  return context;
}

export function usePPUpdateCouponStore() {
  const context = React.use(PPCouponUpdateStoreContext);

  if (!context) {
    throw new Error('usePPUpdateCouponStore must be used within a PPCouponUpdateStoreContext');
  }

  return context;
}

export function usePpDataStoreInitialization() {
  const store = usePPDataStore<PPDataStoreContextProps>();
  const { updateStore } = usePPDataStoreActions<PPDataStoreContextProps>();
  const router = useRouter();

  React.useEffect(() => {
    if (!store.contentId) {
      const storeDataFromUrl = router.query.storeData;
      const storeString =
        typeof storeDataFromUrl === 'string'
          ? decodeURIComponent(storeDataFromUrl)
          : sessionStorage.getItem(PP_STORE_DATA_KEY);

      if (storeString) {
        try {
          const restoredStore = JSON.parse(storeString) as PPDataStoreContextProps;

          if (restoredStore) {
            updateStore({ ...restoredStore });
          }

          sessionStorage.removeItem(PP_STORE_DATA_KEY);

          if (storeDataFromUrl) removeQueryParam('storeData', router);
        } catch (error) {
          console.error((error as Error).message);
        }
      }
    }
  }, [router, store, updateStore]);
}
