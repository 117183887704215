'use client';

import uuidValidator from '@haaretz/s-validators/uuidValidator';
import React from 'react';

type ArticleIdValue = string | null;

const ArticleIdContext = React.createContext<ArticleIdValue | undefined>(undefined);
const ArticleIdSetter = React.createContext<((articleId: ArticleIdValue) => void) | undefined>(
  undefined
);

export function ArticleIdProvider({
  children,
  value: defaultArticleId,
}: React.PropsWithChildren<{ value: ArticleIdValue }>) {
  const [articleId, setArticleId] = React.useState<ArticleIdValue>(defaultArticleId);

  const articleIdSetter = React.useCallback((value: ArticleIdValue) => {
    const newArticleId = value && uuidValidator(value) ? value : null;

    setArticleId(newArticleId);
  }, []);

  return (
    <ArticleIdContext value={articleId}>
      <ArticleIdSetter value={articleIdSetter}>{children}</ArticleIdSetter>
    </ArticleIdContext>
  );
}

export default function useArticleId() {
  const context = React.use(ArticleIdContext);

  if (context === undefined) {
    throw new Error('useArticleId must be used within a ArticleIdProvider');
  }

  return context;
}

export function useSetArticleId() {
  const context = React.use(ArticleIdSetter);

  if (context === undefined) {
    throw new Error('useSetArticleId must be used within a ArticleIdProviderMutator');
  }

  return context;
}

export function useArticleIdAtom() {
  return [useArticleId(), useSetArticleId()] as const;
}
